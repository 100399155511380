@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
    font-family: "Beastie";
    src: url("assets/fonts/Beastie.ttf");
}

@font-face {
    font-family: "Beastie Bold";
    src: url("assets/fonts/Beastie Bold.ttf");
}
